import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { ReactComponent as IconBack } from '../../../../assets/back-icon.svg';
import { ReactComponent as IconClose } from '../../../../assets/close.svg';
import { ReactComponent as IconInfo } from '../../../../assets/info.svg';
import * as $ from 'jquery';
import * as moment from "moment";
import 'moment/locale/es';
import es from 'date-fns/locale/es';
import DeliveryExpress from "./DeliveryExpress";

registerLocale('es', es);

class Navigation extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        stores: [],
        showPrices: true,
        storesModal: {
            mode: '',
            selectedAddress: null,
            selectedStore: null,
            pickUpStore: [],
            pickUpStoreDateRange: {
                min: new Date(),
                max: new Date()
            },
            searchedAddress: null,
            pickUpDateScheadule: [],
            defaultLat: -12.0986256,
            defaultLong: -77.0703135
        },
        selectedStore: null,
        alternativePickupPersonFullName: '',
        alternativePickupPersonPhone: '',
        alternativePickupPersonIdentificationNumber: "",
        alternativePickupPersonIdentificationType: "1",
        documentNumberLength: 8,
        errors: {}
    }

    componentDidMount = () => {
        moment.locale('es');
        this.mergeItems();
        this.loadPromo();
    }

    componentWillReceiveProps = (newProps) => {
        const { stores } = this.state;
        const { checkout } = newProps;

        if (!(this.props.currentForm === "address" && newProps.currentForm === "shipping"))
            return;

        setTimeout(() => {
            stores.forEach((item, i) => {
                let slas = [];
                let sla = [];
                let deliveryWindows = [];
                if (checkout) {
                    var itemSD = this.getStoreShippingData(item.store);

                    if (!itemSD)
                        return;

                    let li = itemSD.shippingData.shippingData ? itemSD.shippingData.shippingData.logisticsInfo : itemSD.shippingData.logisticsInfo;
                    li.forEach((el, i) => {
                        item.products.forEach((p, j) => {
                            if (el.itemIndex == p.requestIndex || p.id === el.itemId) {
                                slas.push(el);
                            }
                        })
                    })
                }

                if (slas.length > 0) {
                    sla = slas[0].slas.filter((s, i) => {
                        return s.deliveryChannel == "delivery";
                    });

                    if (sla.length > 0) {
                        sla = sla[0];
                        sla.availableDeliveryWindows.forEach((dw, i) => {
                            deliveryWindows.push(new Date(dw.startDateUtc));
                        })
                    }

                    if (deliveryWindows.length > 0)
                        this.handleChangeDeliveryDate(deliveryWindows[0], item, sla.availableDeliveryWindows);
                }
            })
        }, 500);
    }

    loadPromo = () => {
        const { promo } = this.props;
        if (promo) {
            this.setState({
                showPrices: promo.showPrices
            })
        }
    }

    formatMoney = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN' }).format(amount) : 'S/ 0.00';
    }

    formatNumber = (amount) => {
        return amount ? new Intl.NumberFormat('es-PE').format(amount) : '0';
    }

    mergeItems = () => {
        const { checkout, address } = this.props;
        let storedData = localStorage.getItem("__state__");
        if (storedData) {

            storedData = JSON.parse(storedData);
            this.setState({
                stores: storedData.stores
            });
            return;
        }

        let externalCart = checkout.externalCart.map((el, i) => {
            el.shippingData = JSON.parse(el.externalJsonCart);
            return el;
        })
        let sItems = [];

        if (externalCart) {
            externalCart.forEach((cart, i) => {
                cart.shippingData.items.forEach((el, i) => {

                    let exist = sItems.find((sItem) => {
                        return sItem.id === el.id && sItem.sellingPrice === el.sellingPrice;
                    });

                    el.storeInfo = cart.shippingData.sellers[0];
                    // el.priceToShow = el.quantity * el.sellingPrice;

                    if (!exist) {
                        sItems.push(el);
                    }
                    // else {
                    //     sItems.forEach((sItem, j) => {
                    //         if(sItem.id === el.id) {
                    //             sItem.priceToShow += el.priceToShow;
                    //             sItem.quantity += el.quantity;
                    //         }
                    //     })
                    // }
                })
            })
        }

        let stores = {};
        let storesArray = [];
        let gItems = checkout.items;

        sItems.forEach((s, i) => {
            var itemSD = externalCart.filter((store, idx) => {
                return s.seller == store.externalSellerId;
            })

            if (itemSD && itemSD.length > 0) {
                itemSD = itemSD[0];
            }

            let lInfo = itemSD.shippingData ? itemSD.shippingData.logisticsInfo : itemSD.logisticsInfo;

            if (!lInfo) {
                if (itemSD.shippingData.shippingData)
                    lInfo = itemSD.shippingData.shippingData.logisticsInfo;
            }

            gItems.forEach((g, j) => {
                if (s.id === g.productId) {
                    s.productInfo = g;
                }
            });

            if (lInfo) {
                lInfo.forEach((l, k) => {
                    if (s.requestIndex === l.itemIndex || s.id === l.itemId) {
                        s.logisticInfo = l;
                    }
                })
            }
        })

        sItems.forEach((el, i) => {
            let key = el.productInfo.sellerName.replace(/ /g, "");
            if (key === "")
                key = el.seller;

            if (stores[key]) {
                stores[key].products.push(el);
            } else {
                stores[key] = {
                    products: [el]
                }
            }
        });

        Object.keys(stores).forEach(storeKey => {
            const store = stores[storeKey];
            let shippingMethods = store.products.reduce((methods, el, i) => {
                if (el.logisticInfo) {
                    if (i === 0 || methods.length > el.logisticInfo.deliveryChannels.length) {
                        return el.logisticInfo.deliveryChannels;
                    }
                }
                return methods;
            }, []);

            const firstProduct = store.products[0];
            const sellerName = firstProduct.productInfo.sellerName;
            const storeName = sellerName && sellerName.length > 0 ? sellerName : firstProduct.storeInfo.name;

            const storeSeller = firstProduct.seller !== firstProduct.storeInfo.name ? firstProduct.seller : firstProduct.storeInfo.name;

            storesArray.push({
                storeName: storeName,
                store: storeSeller,
                storeInfo: firstProduct.storeInfo,
                products: store.products,
                shippingMethods: shippingMethods,
                shippingMethod: '' // No selection of the first shipping method
            });
        });


        if (address) {
            storesArray.forEach((el, i) => {
                el.address = {
                    addressType: "residential",
                    addressId: "123",
                    department: address.department,
                    province: address.productId,
                    district: address.district,
                    addressLine: address.addressLine,
                    addressNumber: address.addressNumber,
                    reference: address.reference,
                    countryISO: "PER",
                    postalCode: address.postalCode,
                };
                el.selectedDates = [];
                el.selectedDate = null;
                el.scheadule = [];
                el.selectedDeliveryDate = null;
                el.selectedDeliveryWindow = null;
                el.selectedDeliverySla = null;
                el.selectedDeliveryScheadule = null;
                el.deliveryScheadule = [];
            })
        }

        this.setState({
            stores: storesArray
        });
    }

    getShippingName = (key) => {
        let shippingNames = {
            'delivery': 'Entrega a domicilio',
            'pickup-in-point': 'Retiro en tienda',
            'express': 'Envio express'
        }

        return shippingNames[key];
    }

    onChangeShippingMethod = (value, store, availability) => {
        if (!availability)
            return;

        let stores = this.state.stores;

        stores.forEach((el, i) => {
            if (el.store === store.store)
                el.shippingMethod = value
        });

        this.setState({ stores: stores });
    }

    renderShippingProductsV2 = (store) => {
        const { checkout } = this.props;
        const { selectedStore } = this.state;

        checkout.externalCart.forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonCart);
            if (cart.items[0].seller === store.store) {
                store.shippingMethods = [];

                if (cart.logisticsInfo == undefined) {
                    store.shippingMethods = cart.shippingData.logisticsInfo[0].deliveryChannels
                } else {
                    store.shippingMethods = cart.logisticsInfo[0].deliveryChannels
                }

                store.shippingMethods = store.shippingMethods.sort();

                store.products.forEach((i, j) => {
                    let _item = cart.items.find((ci, k) => {
                        return i.id === ci.id;
                    })

                    if (_item) {
                        i.requestIndex = _item.requestIndex;
                    }
                })
            }
        })

        let deliveryExpressSla = null;
        let firstDeliveryTime = '';

        store.products.forEach((p, j) => {
            //console.log("Product ==>", p);
            let externalCart = checkout.externalCart;
            externalCart.forEach((c, k) => {
                if (store.store === c.externalSellerId) {
                    let cart = JSON.parse(c.externalJsonCart);
                    let lInfo = [];

                    if (cart.logisticsInfo == undefined)
                        lInfo = cart.shippingData.logisticsInfo;
                    else
                        lInfo = cart.logisticsInfo;

                    store._logisticInfo = lInfo;
                    lInfo.forEach((item, l) => {
                        if (p.requestIndex === item.itemIndex || p.id === item.itemId) {
                            let pickupSlas = [];
                            let deliverySlas = [];
                            if (item.slas && item.slas.length > 0) {
                                pickupSlas = item.slas.filter((_el, i) => { return _el.deliveryChannel === "pickup-in-point" });
                                deliverySlas = item.slas.filter((_el, i) => { return _el.deliveryChannel === "delivery" && !(["Entrega hoy shopstar", "Entrega ND shopstar"].includes(_el.id)) });
                                deliveryExpressSla = item.slas.find((_el, i) => { return _el.deliveryChannel === "delivery" && (["Entrega hoy shopstar", "Entrega ND shopstar"].includes(_el.id)) });
                                // deliveryExpressSla = {
                                //     "id": "Entrega hoy shopstar",
                                //     "deliveryChannel": "delivery",
                                //     "name": "Entrega hoy shopstar",
                                //     "deliveryIds": [
                                //         {
                                //             "courierId": "4_3",
                                //             "warehouseId": "VD1",
                                //             "dockId": "4",
                                //             "courierName": "Coolbox Delivery  Shopstar hoy",
                                //             "quantity": 1,
                                //             "kitItemDetails": []
                                //         }
                                //     ],
                                //     "shippingEstimate": "0d",
                                //     "shippingEstimateDate": null,
                                //     "lockTTL": null,
                                //     "availableDeliveryWindows": [],
                                //     "deliveryWindow": null,
                                //     "price": 1090,
                                //     "listPrice": 1090,
                                //     "tax": 0,
                                //     "pickupStoreInfo": {
                                //         "isPickupStore": false,
                                //         "friendlyName": null,
                                //         "address": null,
                                //         "additionalInfo": null,
                                //         "dockId": null
                                //     },
                                //     "pickupPointId": null,
                                //     "pickupDistance": null,
                                //     "polygonName": "",
                                //     "transitTime": "0d"
                                // };
                                store.deliveryExpressSla = deliveryExpressSla;

                                store.availablePickup = pickupSlas.length > 0;
                                store.availableDelivery = deliverySlas.length > 0;
                                store.shippindMethodsAvailability = {
                                    "pickup-in-point": pickupSlas.length > 0,
                                    "delivery": deliverySlas.length > 0
                                }

                                if (!store.shippingMethod || store.shippingMethod === "") {
                                    if (deliveryExpressSla) {
                                        store.shippingMethod = "express"
                                    } else if (store.availableDelivery) {
                                        store.shippingMethod = "delivery"
                                    } else {
                                        store.shippingMethod = "pickup-in-point"
                                    }
                                }

                                if (deliveryExpressSla) {
                                    let express = store.shippingMethods.find((shippingMethod) => shippingMethod.id === 'express');

                                    store.shippingMethods.unshift({
                                        id: 'express'
                                    });
                                    store.shippindMethodsAvailability['express'] = true;
                                }

                                if (deliverySlas.length > 0) {
                                    let sla = deliverySlas.find((_el) => { return _el.id === "Despacho a Domicilio" })
                                    if (sla) {
                                        if (sla.availableDeliveryWindows.length > 0) {
                                            firstDeliveryTime = moment(sla.availableDeliveryWindows[0].startDateUtc).format("D [de] MMMM");
                                        }
                                    }
                                }
                            } else {
                                store.shippindMethodsAvailability = {
                                    "pickup-in-point": false,
                                    "delivery": false,
                                    "express": false
                                }
                                store.shippingMethod = "delivery"
                            }
                            if (!item.slas || item.slas.length === 0) {

                                p.outOfStock = true;
                            }
                        }
                    })
                }
            })
        });

        let shippingCost = 0
        checkout.externalCart.forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonCart);
            if (cart.items[0].seller === store.store) {
                let totals = cart.totals ? cart.totals : cart.totalizers;
                if (totals && totals.length > 0) {
                    let shipping = totals.find((t, j) => {
                        return t.id === "Shipping";
                    });

                    if (shipping) {
                        if (shipping.value > 0) {
                            shippingCost = shipping.value;
                        }
                    }
                }
            }
        });

        let shippingMethods = "";
        let today = moment().format("[Hoy] D [de] MMMM");

        if (store && store.shippingMethods.length > 0) {
            let temp = [];
            store.shippingMethods.forEach((el) => {
                let exist = temp.find((_el) => _el.id === el.id);

                if (!exist)
                    temp.push(el);
            })

            store.shippingMethods = temp;

            shippingMethods = (
                <React.Fragment>
                    <div className="store-shipping__methods">
                        {
                            <React.Fragment>
                                {
                                    store.shippingMethods.map((sm, j) => {
                                        return (
                                            <React.Fragment key={store.store + sm.id}>
                                                {/* {
                                                    (store.shippindMethodsAvailability && store.shippindMethodsAvailability[sm.id]) &&
                                                    <div onClick={(e) => { this.onChangeShippingMethod(sm.id, store, store.shippindMethodsAvailability[sm.id]) }}
                                                        className={'store-shipping__methods__item ' + (store.shippingMethod === sm.id ? ' selected' : '') + (!store.shippindMethodsAvailability[sm.id] ? ' disabled' : '')}>
                                                        {
                                                            (sm.id === 'express') &&
                                                            <React.Fragment>
                                                                <h5>
                                                                    <label>Express</label>
                                                                    {today}
                                                                </h5>
                                                                <span className="price">{this.formatMoney(deliveryExpressSla.listPrice / 100)}</span>
                                                                <span>Disponible si lo compras antes de las <b className="text-danger">12:00 pm</b></span>
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            (sm.id === 'delivery') &&
                                                            <React.Fragment>
                                                                <h5>{firstDeliveryTime}</h5>
                                                                <span className="price">{this.formatMoney(shippingCost / 100)}</span>
                                                                <span>O puedes programar una fecha de entrega</span>
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            (sm.id === 'pickup-in-point') &&
                                                            <React.Fragment>
                                                                <h5>Retirar en tienda</h5>
                                                                <span className="price">GRATIS</span>
                                                                <span>Elige la tienda más cercana</span>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                } */}
                                                {
                                                    (store.shippindMethodsAvailability && store.shippindMethodsAvailability[sm.id]) &&
                                                    <div className="col-6">
                                                        <div onClick={(e) => { this.onChangeShippingMethod(sm.id, store, store.shippindMethodsAvailability[sm.id]) }}
                                                            className={'store-shipping__methods__item ' + (store.shippingMethod === sm.id ? ' selected' : '') + (!store.shippindMethodsAvailability[sm.id] ? ' disabled' : '')}>
                                                            {
                                                                (sm.id === 'express') && <img className="shipping__methods__item__icon" src="/express.png" />
                                                            }
                                                            {
                                                                (sm.id === 'delivery') && <img className="shipping__methods__item__icon" src="/delivery.png" />
                                                            }
                                                            {
                                                                (sm.id === 'pickup-in-point') && <img className="shipping__methods__item__icon" src="/store.png" />
                                                            }
                                                            <span>{this.getShippingName(sm.id)}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </React.Fragment>

                                        )
                                    })
                                }
                            </React.Fragment>

                        }
                    </div>
                    <div id={'container-' + store.store}>
                        {(['express'].includes(store.shippingMethod)) &&
                            <DeliveryExpress sla={store.deliveryExpressSla} />
                        }
                        {(store.shippingMethod === 'delivery') &&
                            this.renderProductDelivery(store)
                        }
                        {(store.shippingMethod === 'pickup-in-point') && (
                            <React.Fragment>
                                {
                                    (store.selectedSla) &&
                                    this.renderProductPickup(store)
                                }
                                {
                                    (!store.selectedSla) &&
                                    <React.Fragment>
                                        {this.showOpenStoresModal(store)}
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )}
                    </div>
                </React.Fragment>
            )
        }

        if (store.storeInfo.id === "1") {
            return "";
        }
        return (<React.Fragment>{shippingMethods}</React.Fragment>);
    }

    renderAlternativePickupPerson = (store) => {
        return (
            <React.Fragment>
                <div className="alternative-pickup-person">
                    {
                        (store.alternativePickupPersonFullName && store.alternativePickupPersonFullName !== '') &&
                        <React.Fragment>
                            <span>{store.alternativePickupPersonFullName} ({store.alternativePickupPersonIdentificationNumber})</span>
                            <a role="button" onClick={(e) => { this.handleEditAlternativePickupPerson(store); }}>Editar</a>
                            <a role="button" onClick={(e) => { this.handleRemoveAlternativePickupPerson(store); }}>Eliminar</a>
                        </React.Fragment>
                    }
                    {
                        (!store.alternativePickupPersonFullName || store.alternativePickupPersonFullName === '') &&
                        <a role="button" onClick={(e) => { this.handleAddAlternativePickupPerson(store); }}>¿Deseas que alguien recoja la compra por ti?</a>
                    }
                </div>
            </React.Fragment>
        );
    }

    handleEditAlternativePickupPerson = (store) => {
        this.setState({
            alternativePickupPersonFullName: store.alternativePickupPersonFullName,
            alternativePickupPersonIdentificationNumber: store.alternativePickupPersonIdentificationNumber,
            alternativePickupPersonIdentificationType: store.alternativePickupPersonIdentificationType,
            alternativePickupPersonPhone: store.alternativePickupPersonPhone,
        });

        this.toggleModal("#alternativePickupPersonModal", "show");
    }

    handleRemoveAlternativePickupPerson = (store) => {
        const { selectedStore } = this.state;
        let stores = this.state.stores;

        stores.forEach((el, i) => {
            if (selectedStore.store === el.store) {
                el.alternativePickupPersonFullName = null;
                el.alternativePickupPersonIdentificationNumber = null;
                el.alternativePickupPersonIdentificationType = null;
                el.alternativePickupPersonPhone = null;
            }
        });

        this.setState({
            stores: stores
        });
    }

    handleAddAlternativePickupPerson = (store) => {
        this.setState({ selectedStore: store });
        this.toggleModal('#alternativePickupPersonModal', 'show');
    }

    handleSaveAlternativePickupPerson = () => {
        const { selectedStore } = this.state;
        let stores = this.state.stores;

        if (!this.validState()) {
            return;
        }

        stores.forEach((el, i) => {
            if (selectedStore.store === el.store) {
                el.alternativePickupPersonFullName = this.state.alternativePickupPersonFullName;
                el.alternativePickupPersonIdentificationNumber = this.state.alternativePickupPersonIdentificationNumber;
                el.alternativePickupPersonIdentificationType = this.state.alternativePickupPersonIdentificationType;
                el.alternativePickupPersonPhone = this.state.alternativePickupPersonPhone;
            }
        });

        this.setState({
            stores: stores,
            alternativePickupPersonFullName: '',
            alternativePickupPersonIdentificationNumber: '',
            alternativePickupPersonIdentificationType: '1',
            alternativePickupPersonPhone: ''
        });

        this.toggleModal("#alternativePickupPersonModal", "hide");
    }

    toggleModal = (modal, action) => {
        $(modal).modal(action);
    }

    renderShippingProducts = (store) => {
        const { checkout } = this.props;
        const { selectedStore } = this.state;

        checkout.externalCart.forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonCart);
            if (cart.items[0].seller === store.store) {
                store.shippingMethods = [];

                if (cart.logisticsInfo == undefined) {
                    store.shippingMethods = cart.shippingData.logisticsInfo[0].deliveryChannels
                } else {
                    store.shippingMethods = cart.logisticsInfo[0].deliveryChannels
                }

                store.shippingMethods = store.shippingMethods.sort();

                store.products.forEach((i, j) => {
                    let _item = cart.items.find((ci, k) => {
                        return i.id === ci.id;
                    })

                    if (_item) {
                        i.requestIndex = _item.requestIndex;
                    }
                })
            }
        })

        store.products.forEach((p, j) => {
            //console.log("Prodyct ==>", p);
            let externalCart = checkout.externalCart;
            externalCart.forEach((c, k) => {
                if (store.store === c.externalSellerId) {
                    let cart = JSON.parse(c.externalJsonCart);
                    let lInfo = [];

                    if (cart.logisticsInfo == undefined)
                        lInfo = cart.shippingData.logisticsInfo;
                    else
                        lInfo = cart.logisticsInfo;

                    store._logisticInfo = lInfo;
                    lInfo.forEach((item, l) => {
                        if (p.requestIndex === item.itemIndex || p.id === item.itemId) {
                            let pickupSlas = [];
                            let deliverySlas = [];
                            if (item.slas && item.slas.length > 0) {
                                pickupSlas = item.slas.filter((_el, i) => { return _el.deliveryChannel === "pickup-in-point" });
                                deliverySlas = item.slas.filter((_el, i) => { return _el.deliveryChannel === "delivery" });

                                store.availablePickup = pickupSlas.length > 0;
                                store.availableDelivery = deliverySlas.length > 0;
                                store.shippindMethodsAvailability = {
                                    "pickup-in-point": pickupSlas.length > 0,
                                    "delivery": deliverySlas.length > 0
                                }

                                if (!store.shippingMethod || store.shippingMethod === "") {
                                    if (store.availableDelivery) {
                                        store.shippingMethod = "delivery"
                                    } else {
                                        store.shippingMethod = "pickup-in-point"
                                    }
                                }
                            } else {
                                store.shippindMethodsAvailability = {
                                    "pickup-in-point": false,
                                    "delivery": false
                                }
                                store.shippingMethod = "delivery"
                            }
                            if (!item.slas || item.slas.length === 0) {

                                p.outOfStock = true;
                            }
                        }
                    })
                }
            })
        })

        let shippingMethods = "";

        if (store && store.shippingMethods.length > 0) {
            shippingMethods = (
                <React.Fragment>
                    <div className="store-shipping__methods row">
                        {
                            store.shippingMethods.map((sm, j) => {
                                return (
                                    <React.Fragment key={store.store + sm.id}>
                                        {
                                            (store.shippindMethodsAvailability && store.shippindMethodsAvailability[sm.id]) &&
                                            <div className="col-6">
                                                <div onClick={(e) => { this.onChangeShippingMethod(sm.id, store, store.shippindMethodsAvailability[sm.id]) }}
                                                    className={'store-shipping__methods__item ' + (store.shippingMethod === sm.id ? ' selected' : '') + (!store.shippindMethodsAvailability[sm.id] ? ' disabled' : '')}>
                                                    {
                                                        (sm.id === 'delivery') && <img className="shipping__methods__item__icon" src="/delivery.png" />
                                                    }
                                                    {
                                                        (sm.id === 'pickup-in-point') && <img className="shipping__methods__item__icon" src="/store.png" />
                                                    }
                                                    <span>{this.getShippingName(sm.id)}</span>
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>

                                )
                            })
                        }
                    </div>
                    <div id={'container-' + store.store}>
                        {(store.shippingMethod === 'delivery') &&
                            this.renderProductDelivery(store)
                        }
                        {(store.shippingMethod === 'pickup-in-point') && (
                            <React.Fragment>
                                {
                                    (store.selectedSla) &&
                                    <React.Fragment>
                                        {this.renderProductPickup(store)}
                                        {this.renderAlternativePickupPerson(store)}
                                    </React.Fragment>
                                }
                                {
                                    (!store.selectedSla) &&
                                    <React.Fragment>
                                        {this.showOpenStoresModal(store)}
                                        {this.renderAlternativePickupPerson(store)}
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )}
                    </div>
                </React.Fragment>
            )
        }

        return (<React.Fragment>{shippingMethods}</React.Fragment>);
    }

    getStoreShippingData = (storeId) => {
        const { checkout } = this.props;

        let externalCart = checkout.externalCart.map((el, i) => {
            el.shippingData = JSON.parse(el.externalJsonCart);
            return el;
        })

        let itemSD = externalCart.filter((store, idx) => {
            return store.externalSellerId == storeId;
        })

        if (itemSD && itemSD.length > 0) {
            return itemSD[0];
        }
        return null;
    }

    getStoreShippingCost = (store) => {
        const { checkout } = this.props;
        const { showPrices } = this.state;
        let cost = 0;
        let priceText = "";
        let slas = true;

        //console.log(store);

        // checkout.externalCart.forEach((el, i) => {
        //     let cart = JSON.parse(el.externalJsonCart);
        //     if (cart.items[0].seller === store.store) {
        //         if (store.store === '1') {
        //             let products = store.products.map((p, j) => { return p.id; });
        //             let purchaseConditions = cart.purchaseConditions;

        //             if (purchaseConditions) {
        //                 let items = purchaseConditions.itemPurchaseConditions;
        //                 items.forEach(item => {
        //                     if (products.indexOf(item.id) > -1) {
        //                         let sla = item.slas ? item.slas[0] : null;
        //                         if (sla) {
        //                             cost += sla.listPrice;
        //                         } else {
        //                             slas = false;
        //                         }
        //                     }
        //                 });
        //             }
        //         } else {
        //             let logisticInfo = store._logisticInfo

        //             logisticInfo.forEach((li) => {
        //                 if (li.slas && li.slas.length > 0) {
        //                     let liSla = li.slas.find((sla) => {
        //                         return sla.deliveryChannel === 'delivery';
        //                     })

        //                     if (liSla) {
        //                         cost += liSla.price;
        //                     }
        //                 } else {
        //                     slas = false;
        //                 }
        //             })
        //         }
        //     }
        // });

        checkout.externalCart.filter(ec => store.store === ec.externalSellerId).forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonCart);
            let productsIds = store.products.map(p => p.requestIndex);
            // if (cart.items[0].seller === store.store) {
            if (store.store === '1') {
                let products = store.products.map((p, j) => { return p.id; });
                let purchaseConditions = cart.purchaseConditions;

                if (purchaseConditions) {
                    let items = purchaseConditions.itemPurchaseConditions;
                    items.forEach(item => {
                        if (products.indexOf(item.id) > -1) {
                            let sla = item.slas ? item.slas[0] : null;
                            if (sla) {
                                cost += sla.listPrice;
                            } else {
                                slas = false;
                            }
                        }
                    });
                }
            } else {
                let logisticInfo = store._logisticInfo.filter(sli => productsIds.indexOf(sli.itemIndex) > -1);

                logisticInfo.forEach((li) => {
                    if (li.slas && li.slas.length > 0) {
                        let liSla = li.slas.find((sla) => {
                            return sla.deliveryChannel === 'delivery' && !(["Entrega hoy shopstar", "Entrega ND shopstar"].includes(sla.id));
                        })

                        let liExpressSla = li.slas.find((sla) => {
                            return sla.deliveryChannel === 'delivery' && (["Entrega hoy shopstar", "Entrega ND shopstar"].includes(sla.id));
                        })

                        if (liSla && store.shippingMethod === 'delivery') {
                            cost += liSla.price;
                        } else if (liExpressSla && store.shippingMethod === 'express') {
                            cost += liExpressSla.price;
                        }
                    } else {
                        slas = false;
                    }
                })
            }
            // }
        });

        if (!showPrices) {
            priceText = "GRATIS";
        }

        if (store.shippingMethod === 'pickup-in-point') {
            priceText = "GRATIS";
        }

        if (cost === 0) {
            priceText = "GRATIS";
        }

        if (priceText !== "GRATIS") {
            priceText = this.formatMoney(cost / 100);
        }

        return (
            <React.Fragment>
                <p>
                    {
                        (slas) &&
                        <React.Fragment>
                            {
                                (store.shippingMethod === 'pickup-in-point') &&
                                <span>Entrega</span>
                            }
                            {
                                (store.shippingMethod === 'delivery') &&
                                <span>Costo de env&iacute;o - Programado</span>
                            }
                            {
                                (store.shippingMethod === 'express') &&
                                <span>Costo de env&iacute;o - Delivery express</span>
                            }
                            <br />
                            <b>
                                {priceText}
                                <span className="d-none shippingCost">{cost / 100}</span>
                            </b>
                        </React.Fragment>
                    }
                    {
                        (!slas) &&
                        <span>La tienda no cuenta con métodos de envío para tu dirección seleccionada.</span>
                    }
                </p>
            </React.Fragment>
        );
    }

    renderProductDelivery = (item) => {
        const { checkout, address, handleEditAddress } = this.props;
        let slas = [];
        let sla = [];
        let deliveryWindows = [];

        if (checkout) {
            var itemSD = this.getStoreShippingData(item.store);

            if (!itemSD)
                return;

            let li = itemSD.shippingData.shippingData ? itemSD.shippingData.shippingData.logisticsInfo : itemSD.shippingData.logisticsInfo;
            li.forEach((el, i) => {
                item.products.forEach((p, j) => {
                    if (el.itemIndex == p.requestIndex || el.id === p.itemId) {
                        slas.push(el);
                    }
                })
            })
        }

        sla = slas[0].slas.filter((s, i) => {
            return s.deliveryChannel == "delivery";
        });

        if (sla.length > 0) {
            sla = sla[0];
            sla.availableDeliveryWindows.forEach((dw, i) => {
                deliveryWindows.push(new Date(dw.startDateUtc));
            })
        }
        // if (deliveryWindows.length > 0)
        // this.handleChangeDeliveryDate(deliveryWindows[0], item, sla.availableDeliveryWindows);

        let template = (
            <React.Fragment>
                {(deliveryWindows.length > 0) && (
                    <React.Fragment>
                        <div className="store-info__title mt-3">Direcci&oacute;n de env&iacute;o</div>
                        <div className="address-card">
                            <div className="text-right">
                                <button onClick={(e) => { handleEditAddress() }} type="button" className="btn btn-outline-main">Cambiar</button>
                            </div>
                            <div className="address-card__line">
                                <span>{address.addressLine} {address.addressNumber}</span>
                            </div>
                            <div className="address-card__line">
                                <span>{address.district}, {address.province}, {address.department}</span>
                            </div>
                        </div>
                        {/* <div className="store-info__title mt-3">Fecha de entrega</div> */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group date-picker">
                                    <label>Fecha de entrega</label>
                                    <label className="sub-label">Elige la fecha de tu preferencia.</label>
                                    <DatePicker
                                        id={'datepicker-' + item.store}
                                        portalId="root-portal"
                                        className="store-input"
                                        selected={typeof item.selectedDeliveryDate === "string" ? new Date(item.selectedDeliveryDate) : item.selectedDeliveryDate}
                                        placeholderText={'Seleccionar fecha de entrega'}
                                        locale="es"
                                        dateFormat="dd/MM/yyyy"
                                        includeDates={deliveryWindows}
                                        onChange={(e) => { this.handleChangeDeliveryDate(e, item, sla.availableDeliveryWindows) }}
                                    />
                                    <label className="form-icon p-0" htmlFor={'datepicker-' + item.store} role="button">
                                        <i className="far fa-calendar-alt  main-text"></i>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {(item.deliveryScheadule && item.deliveryScheadule.length > 0) && (
                                    <React.Fragment>
                                        <div className="form-group mb-0">
                                            <label>Horario de entrega</label>
                                            {
                                                (item.deliveryScheadule.length === 1) &&
                                                <div>
                                                    <p className="m-0">
                                                        Tu pedido llegará entre el rango de horas.
                                                        <br />
                                                        <b>{item.deliveryScheadule[0].label}</b>
                                                    </p>
                                                </div>
                                            }
                                            {
                                                (item.deliveryScheadule.length > 1) &&
                                                <select className="form-control store-input" name="deliveryTime" value={item.deliveryDeliveryWindowId} onChange={(e) => this.handleChangeDeliveryTime(e, item)}>
                                                    <option value="0">Seleccionar</option>
                                                    {item.deliveryScheadule.map((hour) => {
                                                        return (
                                                            <option key={hour.id} value={hour.id}>{hour.label}</option>
                                                        )
                                                    })}
                                                </select>
                                            }
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {(deliveryWindows.length == 0 && sla.shippingEstimate) && (
                    <div className="card shipping-days">
                        <div className="card-body">
                            <i className="far fa-dot-circle mr-2 text-main"></i>
                            {this.formatEstimate(sla.shippingEstimate)}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );

        return <React.Fragment>{template}</React.Fragment>
    }

    formatEstimate = (e) => {
        let estimate = "";
        if (!e) return "";

        if (e.indexOf("bd") > -1) {
            estimate = e.substring(0, e.indexOf("bd")) + " días hábiles";
        } else if (e.indexOf("d") > -1) {
            estimate = e.substring(0, e.indexOf("d")) + " días";
        } else if (e.indexOf("h") > -1) {
            estimate = e.substring(0, e.indexOf("h")) + " horas";
        }

        return estimate;
    }

    showOpenStoresModal = (store) => {
        let slas = this.getStoreSlas(store);

        slas = slas.filter((el) => { return el.deliveryChannel !== 'delivery' })

        return (
            <React.Fragment>
                <div className="store-info__title mt-3">Tienda de recojo</div>
                <div className="card open-stores-modal-btn" id={"stores-list-" + store.store}>
                    <div className="card-body">
                        {slas.length} tiendas disponibles
                        <a role="button" onClick={(e) => { this.showStoresList(store) }}>Elegir</a>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderProductPickup = (store) => {
        let template = "";
        if (store.selectedSla) {
            template = (
                <React.Fragment>
                    <div className="pickup-store">
                        <div className="store-info__title mt-3">Tienda de recojo</div>
                        <div className="store-address-card">
                            <div className="store-address-card__line">
                                <b>{store.selectedSla.pickupStoreInfo.friendlyName}</b>
                            </div>
                            <button type="button" className="btn btn-outline-main" onClick={(e) => { this.showStoresList(store) }}>Cambiar</button>
                            {/* <div className="store-address-card__line">
                                <span>{store.selectedSla.pickupStoreInfo.address.street} {store.selectedSla.pickupStoreInfo.address.number} {store.selectedSla.pickupStoreInfo.address.complement}</span>
                            </div> */}
                        </div>
                        {/* <div className="store-info__title mt-3">Fecha de recojo</div> */}
                        {store.selectedDates.length > 0 &&
                            <div className="row justify-content-between">
                                <div className="col-md-12">
                                    <div className="form-group date-picker">
                                        <label>Fecha de recojo</label>
                                        <label className="sub-label">Elige la fecha de tu preferencia.</label>
                                        <DatePicker
                                            locale="es"
                                            dateFormat="dd/MM/yyyy"
                                            selected={store.selectedDate ? new Date(store.selectedDate) : null}
                                            includeDates={store.selectedDates}
                                            placeholderText="Seleccionar fecha de recojo"
                                            onChange={(e) => { this.handleChangePickupDate(e, store) }}
                                        />
                                        <i className="far fa-calendar-alt form-icon main-text"></i>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    {store.scheadule.length > 0 && (
                                        <React.Fragment>
                                            <div className="form-group mb-0">
                                                <label>Horario de atención de la tienda</label>
                                                {
                                                    (store.scheadule.length === 1) &&
                                                    <div>
                                                        <p className="m-0">
                                                            {/* Podr&aacute;s recoger tu pedido
                                                            <br /> */}
                                                            <b>{store.scheadule[0].label}</b>
                                                        </p>
                                                    </div>
                                                }
                                                {
                                                    (store.scheadule.length > 1) &&
                                                    <select className="form-control" name="deliveryTime" value={store.selectedScheadule} onChange={(e) => this.handleChangePickupTime(e, store)}>
                                                        <option value="0">Seleccionar</option>
                                                        {store.scheadule.map((hour) => {
                                                            return (
                                                                <option key={hour.id} value={hour.id}>{hour.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                }
                                                {
                                                    (store.store === 'oechsle') &&
                                                    <p className="mt-1">
                                                        Recuerda que tendrás 7 días a partir de la fecha seleccionada para recoger tu pedido; luego de este plazo, el pedido se anulará.
                                                    </p>
                                                }
                                                {
                                                    (store.store !== 'oechsle') &&
                                                    <p className="mt-1">
                                                        Recuerda que tendrás 72 horas a partir de la fecha seleccionada para recoger tu pedido; luego de este plazo, el pedido se anulará.
                                                    </p>
                                                }
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        }
                    </div>
                </React.Fragment>
            )
        }

        return <React.Fragment>{template}</React.Fragment>;
    }

    handleChangeDeliveryDate = (date, item, dws) => {
        const { stores } = this.state;

        let s = stores;
        let stringDate = "";
        let deliveryWindows = []
        let hours = [];

        if (date) {
            let datePicker = $("#container-" + item.store).find(".date-picker");
            datePicker.removeClass("invalid");
            datePicker.find("input").removeClass("invalid");
        }

        date = new Date(date);
        stringDate = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
        deliveryWindows = dws;

        for (var i = 0; i < deliveryWindows.length; i++) {
            let startDate = new Date(deliveryWindows[i].startDateUtc);
            let endDate = new Date(deliveryWindows[i].endDateUtc);
            let sDate = startDate.getFullYear() + '-' + startDate.getMonth() + '-' + startDate.getDate();
            if (stringDate === sDate) {
                let from = startDate.getHours() + 5;
                let to = endDate.getHours() + 5;
                let price = ""
                if (deliveryWindows[i].price == 0) {
                    price = "Gratis";
                } else {
                    price = "S/" + deliveryWindows[i].price;
                }

                hours.push({
                    id: "schdl-" + i,
                    value: deliveryWindows[i].startDateUtc,
                    label: "Desde " + from + ":00 hasta " + to + ":00",
                    data: deliveryWindows[i]
                })
            }
        }

        item.selectedDeliveryDate = new Date(date);
        item.deliveryScheadule = hours;

        item.deliveryDeliveryWindow = hours[0].data;
        item.deliveryDeliveryWindowId = hours[0].id;

        stores.forEach((el, i) => {
            if (el.store === item.store)
                el = item
        });

        this.setState({ stores: s });
    }

    handleChangeDeliveryTime = (e, store) => {
        const { stores } = this.state;
        let date = e.target.value;
        let scheadule = store.deliveryScheadule;
        let _stores = stores;

        if (date && date !== "0" && date !== "") {
            $(e.target).closest('.form-control').removeClass("not-valid");
            $(e.target).removeClass("is-invalid");
        } else {
            // $(e.target).closest('.form-control').addClass("not-valid");
            // $(e.target).addClass("is-invalid");
            return;
        }

        scheadule.forEach((el, i) => {
            if (el.id == date) {
                store.deliveryDeliveryWindow = el.data;
                store.deliveryDeliveryWindowId = el.id;
            }
        });

        _stores.forEach((el, j) => {
            if (el.store === store.store) {
                el = store
            }
        });

        this.setState({ stores: _stores });
    }

    handleChangePickupDate = (date, store) => {
        const { stores } = this.state;

        let _stores = stores;
        let stringDate = "";
        let deliveryWindows = []
        let hours = [];

        date = new Date(date);
        stringDate = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
        deliveryWindows = store.selectedSla.availableDeliveryWindows;

        if (date) {
            let datePicker = $("#container-" + store.store).find(".date-picker");
            datePicker.removeClass("invalid");
            datePicker.find("input").removeClass("invalid");
        }

        for (var i = 0; i < deliveryWindows.length; i++) {
            let startDate = new Date(deliveryWindows[i].startDateUtc);
            let endDate = new Date(deliveryWindows[i].endDateUtc);
            let sDate = startDate.getFullYear() + '-' + startDate.getMonth() + '-' + startDate.getDate();
            if (stringDate === sDate) {
                let from = startDate.getHours() + 5;
                let to = endDate.getHours() + 5;
                let price = ""

                if (deliveryWindows[i].price == 0) {
                    price = "Gratis";
                } else {
                    price = "S/" + deliveryWindows[i].price;
                }

                hours.push({
                    id: "schdl-" + i,
                    value: deliveryWindows[i].startDateUtc,
                    label: "Desde " + from + ":00 hasta " + to + ":00     " + price,
                    data: deliveryWindows[i]
                })
            }
        }

        store.selectedDate = new Date(date);
        store.scheadule = hours;

        store.deliveryWindow = hours[0].data;
        store.selectedScheadule = hours[0].id;

        _stores.forEach((el, i) => {
            if (el.store === store.store)
                el = store
        });

        this.setState({ stores: _stores });
    }

    handleChangePickupTime = (e, store) => {
        const { stores } = this.state;
        let date = e.target.value;
        let _stores = stores;

        store.scheadule.forEach((el, i) => {
            if (el.id == date) {
                store.deliveryWindow = el.data
            }
        });

        _stores.forEach((el, j) => {
            if (el.store === store.store) {
                el = store
            }
        });


        this.setState({ stores: _stores });
    }

    showStoresList = (item) => {
        this.setState({ selectedStore: item });
        $("#storesModal").modal("show");
    }

    hideStoresList = () => {
        $("#storesModal").modal("hide");
    }

    getStoreSlas = (store) => {
        const { checkout } = this.props;

        checkout.externalCart.forEach((el, i) => {
            let cart = JSON.parse(el.externalJsonCart);
            if (cart.items[0].seller === store.store) {
                store.shippingMethods = [];

                if (cart.logisticsInfo == undefined) {
                    store.shippingMethods = cart.shippingData.logisticsInfo[0].deliveryChannels;
                } else {
                    store.shippingMethods = cart.logisticsInfo[0].deliveryChannels;
                }

                store.products.forEach((i, j) => {
                    let _item = cart.items.find((ci, k) => {
                        return i.id === ci.id;
                    })

                    if (_item) {
                        i.requestIndex = _item.requestIndex;
                        if (cart.logisticsInfo == undefined) {
                            i.logisticInfo = cart.shippingData.logisticsInfo.find((li) => {
                                return i.requestIndex === li.itemIndex || i.id === li.itemId;
                            })
                        } else {
                            i.logisticInfo = cart.logisticsInfo.find((li) => {
                                return i.requestIndex === li.itemIndex || i.id === li.itemId;
                            })
                        }

                    }
                })
            }
        })

        let slas = [];
        store.products.forEach((el, i) => {
            if (el.logisticInfo)
                el.logisticInfo.slas.forEach((s, j) => {
                    let exist = slas.find((x) => {
                        return x.id === s.id;
                    })

                    if (!exist) {
                        slas.push(s);
                    }
                })
        })
        return slas;
    }

    renderStores = () => {
        const { checkout } = this.props;
        const { selectedStore, searchTerm } = this.state;

        if (!selectedStore) return "";

        let stores = "";
        let slas = [];

        if (checkout.externalCart) {
            slas = [];
            if (selectedStore)
                slas = this.getStoreSlas(selectedStore);

            
            

            const slasTemp = slas.filter((e) => e.id != 'Retiro en Tienda LA CURACAO - SALAVERRY ')
       
            const filteredSlas = slasTemp.filter((store) => {
                if (searchTerm && searchTerm.length >= 3) {

                    const neighborhood = store && store.pickupStoreInfo && store.pickupStoreInfo.address
                        ? store.pickupStoreInfo.address.neighborhood
                        : undefined;

                    const city = store && store.pickupStoreInfo && store.pickupStoreInfo.address
                        ? store.pickupStoreInfo.address.city
                        : undefined;

                    const state = store && store.pickupStoreInfo && store.pickupStoreInfo.address
                        ? store.pickupStoreInfo.address.state
                        : undefined;


                    return (
                        (neighborhood && neighborhood.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (city && city.toLowerCase().includes(searchTerm.toLowerCase())) ||
                        (state && state.toLowerCase().includes(searchTerm.toLowerCase()))
                    );
                }
                return true;
            });
            
            stores = filteredSlas.map((store, index) => {
                if (store.pickupStoreInfo && store.pickupStoreInfo.address)
                    return (
                        <div className="card" key={'s-' + index}>
                            <div className="card-body">
                                <div className="store-info">
                                    <h4>{store.pickupStoreInfo.friendlyName}</h4>
                                    <p>{`${store.pickupStoreInfo.address.street} ${store.pickupStoreInfo.address.number} - ${store.pickupStoreInfo.address.neighborhood}, ${store.pickupStoreInfo.address.state}, ${store.pickupStoreInfo.address.city}`} {store.pickupStoreInfo.address.complement}</p>
                                    <button className="btn btn-main" onClick={(e) => { this.selectStore(store) }}>Elegir esta tienda</button>
                                </div>
                            </div>
                        </div>
                    );
                else {
                    return (<span key={'s-' + index}></span>);
                }
            });
        }
        const slasTemp = slas.filter((e) => e.id != 'Retiro en Tienda LA CURACAO - SALAVERRY ')
        slas = slasTemp.filter((el) => el.deliveryChannel !== 'delivery');

        return (
            <React.Fragment>
                <div className="checkout__navigation">
                    <a role="button" onClick={this.hideStoresList}>
                        <IconBack />
                        <span>Regresar</span>
                    </a>
                </div>
                <h4 className="d-block">Elige una tienda <span className="text-capitalize">{selectedStore.storeName}</span></h4>
                <p>Podrás recojer tu producto en cualquier tienda sin costo.</p>
                <p>Tiendas disponibles: {slas.length}</p>

                <div className="form-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Buscar tienda por distrito, provincia o departamento"
                        value={this.state.searchTerm || ''}
                        onChange={(e) => this.setState({ searchTerm: e.target.value })}
                    />
                </div>

                {stores.length > 0 ? stores : <p>No hay tiendas que coincidan con la búsqueda.</p>}
            </React.Fragment>
        );
    };

    selectStore = (store) => {
        const { storesModal } = this.state;

        storesModal.selectedStore = store;
        storesModal.defaultLat = store.pickupStoreInfo.address.geoCoordinates[1];
        storesModal.defaultLong = store.pickupStoreInfo.address.geoCoordinates[0];

        this.setState({ storesModal: storesModal });
        this.savePickupStore();
    }

    savePickupStore = () => {
        const { stores, storesModal, selectedStore } = this.state;
        let ss = selectedStore;
        let _stores = stores;
        let selectedDates = [];

        if (storesModal.selectedStore.availableDeliveryWindows.length > 0) {
            storesModal.selectedStore.availableDeliveryWindows.forEach((dw, i) => {
                selectedDates.push(new Date(dw.startDateUtc));
            });
        }

        let store = storesModal.selectedStore;
        let storeAddress = null;
        if (store) {
            storeAddress = {
                name: store.pickupStoreInfo.friendlyName,
                addressLine1: store.pickupStoreInfo.address.street,
                addressLine2: store.pickupStoreInfo.address.number,
                department: store.pickupStoreInfo.address.state,
                province: store.pickupStoreInfo.address.city,
                district: store.pickupStoreInfo.address.neighborhood,
                postalCode: store.pickupStoreInfo.address.postalCode,
            }
        }

        _stores.forEach((el, i) => {
            if (el.store == ss.store) {
                el = ss;
                el.selectedSla = storesModal.selectedStore;
                el.selectedDates = selectedDates;
                el.selectedDate = null;
                el.scheadule = [];
                el.selectedDeliveryDate = null;
                el.selectedDeliveryWindow = null;
                el.selectedDeliverySla = null;
                el.selectedDeliveryScheadule = null;
                el.deliveryScheadule = [];
                el.address = storeAddress

                if (selectedDates && selectedDates.length > 0)
                    this.handleChangePickupDate(selectedDates[0], el);
            }
        })

        ss.selectedSla = storesModal.selectedStore;
        this.setState({ selectedStore: ss, stores: _stores });

        $("#storesModal").modal("hide");
    }

    saveShippingInfo = async (e) => {
        const { stores } = this.state;
        const { checkout, address, user } = this.props;

        let shippingAvailability = true;
        console.log("================stores================")
        console.log(stores);
        stores.forEach((el, i) => {
            console.log("================stores (el)================")
            console.log(el);
            if (!el.shippindMethodsAvailability['delivery'] && !el.shippindMethodsAvailability['pickup-in-point']) {
                shippingAvailability = false;
            }
            
        });

        if (!shippingAvailability) {
            $("#shippingUnavailabeModal").modal("show");
            return;
        }

        let _stores = stores;
        let _address = address;
        let addresses = [];
        let items = [];
        let department = "";
        let province = "";
        let district = "";
        let isValid = true;
        let message = ""

        stores.forEach((el, i) => {
            let datePicker = $("#container-" + el.store).find(".date-picker");
            let deliveryWindow = $("#container-" + el.store).find("[name=deliveryTime]");
            if (datePicker.length > 0) {
                if (el.shippingMethod === "delivery") {
                    if (!el.selectedDeliveryDate) {
                        datePicker.addClass("invalid");
                        datePicker.find("input").addClass("form-control invalid");
                        isValid = false;
                    }
                } else {
                    if (!el.selectedDate) {
                        datePicker.addClass("invalid");
                        datePicker.find("input").addClass("form-control invalid");
                        isValid = false;
                    }
                }
            }

            if (deliveryWindow.length > 0) {
                if (!deliveryWindow.val() || deliveryWindow.val() === "0") {
                    deliveryWindow.closest('.form-control').addClass("invalid");
                    deliveryWindow.addClass("form-control invalid");
                    isValid = false;
                }
            }

            if (!el.selectedSla && el.shippingMethod === "pickup-in-point") {
                $("#stores-list-" + el.store).addClass("invalid");
                message = "Por favor seleccione una tienda, pare recoger el producto.";
                isValid = false;
            }
        });

        if (!isValid) {
            return;
        }

        stores.forEach((el, i) => {

            checkout.externalCart.forEach((_el, i) => {
                let cart = JSON.parse(_el.externalJsonCart);
                if (cart.items[0].seller === el.store) {
                    el.shippingMethods = [];

                    if (cart.logisticsInfo == undefined) {
                        el.shippingMethods = cart.shippingData.logisticsInfo[0].deliveryChannels;
                    } else {
                        el.shippingMethods = cart.logisticsInfo[0].deliveryChannels;
                    }

                    el.products.forEach((i, j) => {
                        let _item = cart.items.find((ci, k) => {
                            return i.id === ci.id;
                        })

                        if (_item) {
                            i.requestIndex = _item.requestIndex;

                            if (cart.logisticsInfo == undefined) {
                                i.logisticInfo = cart.shippingData.logisticsInfo.find((li) => {
                                    return i.requestIndex === li.itemIndex || i.id === li.itemId;
                                })
                            } else {
                                i.logisticInfo = cart.logisticsInfo.find((li) => {
                                    return i.requestIndex === li.itemIndex || i.id === li.itemId;
                                })
                            }
                        }
                    })
                }
            })

            el.products.forEach((p, j) => {
                let item = null;
                let address = null;
                let addressType = "";

                if (p.logisticInfo.slas.length > 0) {
                    if (el.shippingMethod === "delivery") {
                        let sla = p.logisticInfo.slas.filter((s, k) => {
                            return s.deliveryChannel === "delivery" && !(["Entrega hoy shopstar", "Entrega ND shopstar"].includes(s.id));
                        });

                        sla = sla.length > 0 ? sla[0] : null;
                        el.selectedDeliverySla = sla;

                        item = {
                            "addressId": `addressd-${i}-${j}`,
                            "productId": p.id,
                            "itemIndex": p.requestIndex || j,
                            "selectedDeliveryChannel": el.shippingMethod,
                            "selectedSLAId": el.selectedDeliverySla.id,
                            "price": el.selectedDeliverySla.price / 100,
                            "deliveryWindow": el.deliveryDeliveryWindow,
                            "sellerId": el.store,
                            "shippingEstimate": el.selectedDeliverySla.shippingEstimate,
                            "shippingEstimateDate": el.selectedDeliverySla.shippingEstimateDate,
                        }

                        address = {
                            "addressId": `addressd-${i}-${j}`,
                            "addressType": "residential",
                            "firstName": user.firstName,
                            "lastName": user.lastName,
                            "phone": user.phone,
                            "cellPhone": user.phone,
                            "addressLine": _address.addressLine,
                            "addressNumber": _address.addressNumber,
                            "reference": _address.reference,
                            "countryISO": "PER",
                            "postalCode": _address.postalCode,
                            "department": _address.department,
                            "province": _address.province,
                            "district": _address.district,
                            selectedAddressId: _address.addressId,
                        }
                    } else if (el.shippingMethod === "express") {
                        let sla = p.logisticInfo.slas.filter((s, k) => {
                            return s.deliveryChannel === "delivery" && (["Entrega hoy shopstar", "Entrega ND shopstar"].includes(s.id));
                        });

                        sla = sla.length > 0 ? sla[0] : null;
                        el.selectedDeliverySla = sla;

                        item = {
                            "addressId": `addressd-${i}-${j}`,
                            "productId": p.id,
                            "itemIndex": p.requestIndex || j,
                            "selectedDeliveryChannel": el.shippingMethod,
                            "selectedSLAId": el.selectedDeliverySla.id,
                            "price": el.selectedDeliverySla.price / 100,
                            "deliveryWindow": el.deliveryDeliveryWindow,
                            "sellerId": el.store,
                            "shippingEstimate": el.selectedDeliverySla.shippingEstimate,
                            "shippingEstimateDate": el.selectedDeliverySla.shippingEstimateDate,
                        }

                        address = {
                            "addressId": `addressd-${i}-${j}`,
                            "addressType": "residential",
                            "firstName": user.firstName,
                            "lastName": user.lastName,
                            "phone": user.phone,
                            "cellPhone": user.phone,
                            "addressLine": _address.addressLine,
                            "addressNumber": _address.addressNumber,
                            "reference": _address.reference,
                            "countryISO": "PER",
                            "postalCode": _address.postalCode,
                            "department": _address.department,
                            "province": _address.province,
                            "district": _address.district,
                            selectedAddressId: _address.addressId,
                        }
                    } else if (el.shippingMethod === "pickup-in-point") {
                        item = {
                            "addressId": `addressw-${i}-${j}`,
                            "productId": p.id,
                            "itemIndex": p.requestIndex || j,
                            "selectedDeliveryChannel": el.shippingMethod,
                            "selectedSLAId": el.selectedSla.id,
                            "price": el.selectedSla.price / 100,
                            "deliveryWindow": el.selectedDeliveryWindow,
                            "sellerId": el.store,
                            "storeInfo": el.selectedSla.pickupStoreInfo.address,
                            "shippingEstimateDate": el.selectedSla.shippingEstimateDate,
                            "shippingEstimate": el.selectedSla.shippingEstimate,
                        }

                        address = {
                            "addressId": `addressw-${i}-${j}`,
                            "addressType": "search",
                            "firstName": user.firstName,
                            "lastName": user.lastName,
                            "phone": user.phone,
                            "cellPhone": user.phone,
                            "storeAddressId": el.selectedSla.pickupStoreInfo.address.addressId,
                            "addressLine": el.selectedSla.pickupStoreInfo.address.street,
                            "addressNumber": el.selectedSla.pickupStoreInfo.address.number,
                            "reference": el.selectedSla.pickupStoreInfo.address.reference,
                            "countryISO": el.selectedSla.pickupStoreInfo.address.country,
                            "postalCode": el.selectedSla.pickupStoreInfo.address.postalCode,
                            "department": el.selectedSla.pickupStoreInfo.address.state,
                            "province": el.selectedSla.pickupStoreInfo.address.city,
                            "district": el.selectedSla.pickupStoreInfo.address.neighborhood,
                            "geoCoordinates": el.selectedSla.pickupStoreInfo.address.geoCoordinates,
                            "address": el.address,
                            "alternativePickupPersonFullName": el.alternativePickupPersonFullName,
                            "alternativePickupPersonIdentificationNumber": el.alternativePickupPersonIdentificationNumber,
                            "alternativePickupPersonIdentificationType": el.alternativePickupPersonIdentificationType,
                            "alternativePickupPersonPhone": el.alternativePickupPersonPhone,
                            selectedAddressId: _address.addressId,
                        }
                    }

                    addresses.push(address);
                    items.push(item);
                }
            })
        })

        let model = {
            postalCode: address.postalCode,
            items: items,
            address: addresses,
        }

        this.props.handleSubmitShipping(model, stores);

        // await shippingPut(get.draft.draftId, model, 2)

        // shipping.editing = false;
        // shipping.isValid = true;
        // this.setState({ shipping: shipping });

        // this.saveDataLayerEvent({
        // 	'event': 'virtualPage',
        // 	'pagePath': '/Checkout/IBK/3/Metodo-de-pago',
        // 	'pageName': 'Checkout - Interbank - Método de pago'
        // });
        // this.saveDataLayerStepEvent(3, 'Metodo de pago');
    }

    formatShippingDate = (store) => {
        let date = "";
        if (store.shippingMethod === "delivery") {
            if (store.deliveryDeliveryWindow) {
                return moment(store.deliveryDeliveryWindow.startDateUtc).format('dddd D [de] MMMM');
            } else if (store.selectedDeliverySla) {
                return this.formatEstimate(store.selectedDeliverySla.shippingEstimate);
            }
        } else if (store.shippingMethod === "pickup-in-point") {
            if (store.deliveryWindow) {
                return moment(store.deliveryWindow.startDateUtc).format('dddd D [de] MMMM');
            } else if (store.selectedSla) {
                return this.formatEstimate(store.selectedSla.shippingEstimate);
            }
        }
    }

    formatShippingTime = (store) => {
        let date = "";
        if (store.shippingMethod === "delivery") {
            if (store.deliveryDeliveryWindow) {
                return moment(store.deliveryDeliveryWindow.startDateUtc).add(5, 'hours').format('HH:mm A');
            } else if (store.selectedDeliverySla) {
                return '';
            }
        } else if (store.shippingMethod === "pickup-in-point") {
            if (store.deliveryWindow) {
                return 'Desde ' + moment(store.deliveryWindow.startDateUtc).add(5, 'hours').format('hh:mm A') + ' hasta ' + moment(store.deliveryWindow.endDateUtc).add(5, 'hours').format('hh:mm A');
            } else if (store.selectedSla) {
                return '';
            }
        }
    }

    showEstimatedDate = (date) => {
        if (date && date !== "") {
            if (date.indexOf("bd") > -1) {
                date = date.replace("bd", "");

                return "En " + date + " días hábiles";
            }

            return date;
        }

        return "";
    }

    renderShippingInformation = (store) => {
        const { address, handleEditShipping } = this.props;

        let shippingInfo = (
            <div className="shipping-summary">
                {
                    (store.shippingMethod === 'pickup-in-point') &&
                    <React.Fragment>
                        <div className="text-right">
                            <button className="btn btn-edit btn-outline-main" onClick={(e) => { handleEditShipping() }}>Editar</button>
                        </div>
                        <h4>Recojo en la tienda</h4>
                        <p>{store.selectedSla.pickupStoreInfo.friendlyName}, {store.selectedSla.pickupStoreInfo.address.street} {store.selectedSla.pickupStoreInfo.address.number} {store.selectedSla.pickupStoreInfo.address.complement}</p>
                        <h4 className="mt-3">Fecha de recojo</h4>
                        <p>
                            {this.formatShippingDate(store)}
                        </p>
                    </React.Fragment>
                }
                {
                    (store.shippingMethod === 'delivery') &&
                    <React.Fragment>
                        <div className="text-right">
                            <button className="btn btn-edit btn-outline-main" onClick={(e) => { handleEditShipping() }}>Editar</button>
                        </div>
                        <h4>Entrega a domicilio a</h4>
                        <p>
                            {address.addressLine} {address.addressNumber}, {address.district}, {address.province}, {address.department}
                        </p>
                        <h4 className="mt-3">Fecha de entrega</h4>
                        <p>
                            {this.formatShippingDate(store)}
                        </p>
                    </React.Fragment>
                }
            </div>
        );

        return shippingInfo;
    }

    deleteCartItem = (product) => {
        const { handleDeleteCartItem } = this.props;

        handleDeleteCartItem(product);
    }

    getProductAvailability = (store, product) => {
        const { checkout } = this.props;

        let externalCart = checkout.externalCart;
        let availability = "";

        if (externalCart && externalCart.length > 0) {
            externalCart.forEach((el, i) => {
                let cart = JSON.parse(el.externalJsonShipping);

                if (!cart)
                    cart = JSON.parse(el.externalJsonCart);

                if (cart) {
                    let messages = cart.messages;
                    let items = cart.items;
                    items.forEach((p, j) => {
                        let hasMessage = messages.find((m) => {
                            return m.fields.skuName === p.skuName || m.fields.itemIndex == p.requestIndex;
                        });

                        if (product.id === p.id && hasMessage && hasMessage.code === 'cannotBeDelivered') {
                            availability = (
                                <React.Fragment>
                                    <div className="w-100"></div>
                                    <div className="unavailable-product-message">
                                        Este producto no se encuentra disponible para la dirección de envío. <a role="button" onClick={(e) => { this.deleteCartItem(p); }}>Eliminar</a>.
                                    </div>
                                </React.Fragment>
                            )
                        }
                    })
                }
            })
        }

        return availability;
    }

    handleChangeValue = (field, event) => {
        const { alternativePickupPersonIdentificationType } = this.state;
        let value = event.target.value

        if (field === 'alternativePickupPersonPhone' || (field === 'alternativePickupPersonIdentificationNumber' && alternativePickupPersonIdentificationType === '1')) {
            var re = /^[0-9]*$/;
            if (!re.test(value))
                return;
        }

        if (field === 'alternativePickupPersonFullName') {
            let reLetters = /^[a-zA-Z\s]*$/;
            if (!reLetters.test(value))
                return;
        }

        if (!value || value === "") {
            this.toggleFieldError(field, true)
        } else {
            this.toggleFieldError(field, false)
        }

        this.setState({
            [field]: value
        });
    }

    setDocumentNumberLength = (event) => {
        let value = event.target.value
        let length = 8;

        if (value == '2') {
            length = 9;
        }

        this.setState({
            documentNumberLength: length
        })
    }

    validState = () => {
        let state = this.state;
        let valid = true;
        let errors = {};
        let requiredFields = ["alternativePickupPersonFullName", "alternativePickupPersonIdentificationNumber", "alternativePickupPersonIdentificationType", "alternativePickupPersonPhone"];

        for (let field in state) {
            if (requiredFields.indexOf(field) > -1 && (!state[field] || state[field] === "")) {
                errors[field] = true;
                valid = false;
            }
            if (field === 'alternativePickupPersonPhone') {
                if (state[field].length > 11 || state[field].length < 9) {
                    errors[field] = true;
                    valid = false;
                }
            }
            if (field === 'alternativePickupPersonIdentificationNumber') {
                if (state[field].length !== state.documentNumberLength) {
                    errors[field] = true;
                    valid = false;
                }
            }
        }

        this.setState({ errors });
        return valid;
    }

    toggleFieldError = (name, value) => {
        const { errors } = this.state;
        let _errors = errors;

        _errors[name] = value;
        this.setState({ errors: _errors });
    }

    render() {
        const { stores, showPrices, errors, documentNumberLength, selectedStore } = this.state;
        const { currentForm, completed, loaders, checkout } = this.props;

        return (
            <React.Fragment>
                <form className="checkout__body__form stores-list">
                    <h3 className="checkout__body__form__title">
                        <span className={"number " + ((currentForm === 'shipping' || completed) ? 'purple' : '')}>3</span>
                        Método de entrega
                    </h3>
                    {
                        stores.map((store, i) => {
                            console.log("*****store*****");
                            console.log(store);
                            return (
                                (currentForm === 'shipping' || completed) &&
                                <React.Fragment key={i}>
                                    <div className="card store-info">
                                        <div className="card-body">
                                            <div className="">
                                                {/* <IconStar className="store-info__icon"></IconStar>
                                                        <div className="store-info__name">{store.storeInfo.name}</div>
                                                        <div className="store-info__brand">Tienda oficial</div> */}
                                                <div className="store-info__products">

                                                    {
                                                        store.storeInfo.id != "1" && (
                                                            <div className="store-info__title">
                                                                Tienda {(store.storeName && store.storeName !== '') ? store.storeName : store.storeInfo.name}
                                                            </div>
                                                        ) 
                                                    }

                                                    {
                                                        store.storeInfo.id != "1" &&
                                                        (
                                                            <div className="store-info__subtitle">
                                                                <span>{store.products.length} producto{store.products.length > 1 ? 's' : ''}</span>
                                                            </div>
                                                        )
                                                    }
                                                    
                                                    {
                                                        store.products.map((product, j) => {

                                                            // Definir la variable para el producto relacionado
                                                            let relatedProduct = null;

                                                            // Buscar el producto relacionado si store.store == 1
                                                            if (store.storeInfo.id == "1") {
                                                                console.log("Buscar el producto relacionado ")

                                                                if (product.attachments) {
                                                                    product.attachments.forEach(attachment => {
                                                                        console.log("attachment => ")
                                                                        console.log(attachment)

                                                                        relatedProduct = attachment.content.producto;
                                                                    });
                                                                }
                                                            }

                                                            console.log("relatedProduct => ")
                                                            console.log(relatedProduct)

                                                            return (
                                                                <div className= {'store-info__products__item' + (store.storeInfo.id == 1?' border-0':'')} key={'product-' + j}>
                                                                    <div className="store-info__products__item__image">
                                                                        <img src={product.productInfo.productImage} alt="" />
                                                                    </div>
                                                                    <div className="store-info__products__item__info">
                                                                        <div className="name">{product.productInfo.productName}</div>
                                                                        {
                                                                            relatedProduct && 
                                                                            <div className="store-info__title">
                                                                                Producto: {relatedProduct}
                                                                            </div>
                                                                        }
                                                                        {
                                                                            (product.sellingPrice > 0 && showPrices) &&
                                                                            <React.Fragment>
                                                                                {
                                                                                    !relatedProduct &&
                                                                                    <div className="quantity">
                                                                                        Cantidad {product.productInfo.quantity}
                                                                                    </div>
                                                                                }
                                                                                
                                                                                <div className="price">
                                                                                    {
                                                                                        (product.listPrice > product.sellingPrice) &&
                                                                                        <span className="base">{this.formatMoney(product.listPrice / 100)}</span>
                                                                                    }
                                                                                    <span className="special">{this.formatMoney(product.sellingPrice / 100)}</span>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        }
                                                                    </div>
                                                                    <React.Fragment>
                                                                        {/* {this.getProductAvailability(store, product)} */}
                                                                    </React.Fragment>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            {
                                                (completed) && (store.storeInfo.id != "1") &&
                                                <React.Fragment>
                                                    {this.renderShippingInformation(store)}
                                                </React.Fragment>
                                            }
                                            {
                                                (!completed) &&
                                                <div className="store-shipping">
                                                    {store.storeInfo.id != "1" && (
                                                        <div className="store-info__title">
                                                            Entrega
                                                        </div>
                                                    )}
                                                    {this.renderShippingProductsV2(store)}
                                                </div>
                                            }
                                        </div>
                                        { 
                                        (store.storeInfo.id != "1") &&
                                        <div className="card-footer">
                                            {this.getStoreShippingCost(store)}

                                        </div>
                                        }
                                    </div>
                                </React.Fragment>
                            )
                        })
                    }
                </form >
                {
                    (currentForm === 'shipping') &&
                    <div className="checkout__body__form">
                        <div className="form-group mb-0">
                            <button disabled={loaders.shipping} type="button" className="btn btn-main" onClick={this.saveShippingInfo}>Continuar</button>
                        </div>
                    </div>
                }
                <div className="modal fade pickup-points-modal" id="storesModal" role="dialog" aria-hidden="true" data-backdrop="static">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                {this.renderStores()}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade payment-modal" id="shippingUnavailabeModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <i className="far fa-times-circle fa-2x mb-3"></i>
                                <h4>Las tiendas de las que deseas comprar no tienen habilitadas opciones de env&iacute;o y recojo para la direcci&oacute;n ingresada</h4>
                                <p className="text-center">
                                    Por favor intentalo con una nueva direcci&oacute;n
                                </p>
                                <button className="btn btn-link" type="button" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade alternative-pickup-person-modal pr-0" id="alternativePickupPersonModal" role="dialog" aria-labelledby="milesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header visible-mobile">
                                <a className="close" role="button" onClick={(e) => { this.toggleModal("#alternativePickupPersonModal", "hide") }}>
                                    <IconClose></IconClose>
                                </a>
                            </div>
                            <div className="modal-body">
                                <div className="close-container">
                                    <a className="close" role="button" onClick={(e) => { this.toggleModal("#alternativePickupPersonModal", "hide") }}>
                                        <IconClose></IconClose>
                                    </a>
                                </div>
                                <h3>Datos de la persona que autorizas a recoger tu compra</h3>
                                <div className="row">
                                    <div className="col-md-6 col-12">
                                        <div className={'form-group ' + (errors.alternativePickupPersonFullName ? 'invalid' : '')}>
                                            <label>Nombre completo</label>
                                            <input type="text" className="form-control"
                                                placeholder="Ingresa el nombre y apellido"
                                                value={this.state.alternativePickupPersonFullName}
                                                onChange={(e) => this.handleChangeValue('alternativePickupPersonFullName', e)} />
                                            <span className="input-error">
                                                {
                                                    errors.alternativePickupPersonFullName &&
                                                    <React.Fragment>Campo obligatorio.</React.Fragment>
                                                }
                                            </span>
                                        </div>
                                        <div className={'form-group ' + (errors.alternativePickupPersonPhone ? 'invalid' : '')}>
                                            <label>Celular</label>
                                            <input type="text" className="form-control"
                                                placeholder="Ingresa el celular"
                                                maxLength="11"
                                                value={this.state.alternativePickupPersonPhone}
                                                onChange={(e) => this.handleChangeValue('alternativePickupPersonPhone', e)} />
                                            <span className="input-error">
                                                {
                                                    (errors.alternativePickupPersonPhone && this.state.alternativePickupPersonPhone.length > 0) &&
                                                    <React.Fragment>Ingresa un celular v&aacute;lido.</React.Fragment>
                                                }
                                                {
                                                    (errors.alternativePickupPersonPhone && (!this.state.alternativePickupPersonPhone || this.state.alternativePickupPersonPhone.length === 0)) &&
                                                    <React.Fragment>Campo obligatorio.</React.Fragment>
                                                }
                                            </span>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className={'col-md-4 col-4 ' + (errors.alternativePickupPersonIdentificationType ? 'invalid' : '')}>
                                                            <label>Tipo</label>
                                                            <select className="form-control" value={this.state.alternativePickupPersonIdentificationType}
                                                                onChange={(e) => { this.handleChangeValue('alternativePickupPersonIdentificationType', e); this.setDocumentNumberLength(e) }}>
                                                                <option value="1">DNI</option>
                                                                <option value="2">CE</option>
                                                            </select>
                                                        </div>
                                                        <div className={'col-md-8 col-8 ' + (errors.alternativePickupPersonIdentificationNumber ? 'invalid' : '')}>
                                                            <label>N&uacute;mero de documento</label>
                                                            <input type="text" className="form-control"
                                                                placeholder="Ingresa el n&uacute;mero"
                                                                maxLength={documentNumberLength}
                                                                value={this.state.alternativePickupPersonIdentificationNumber}
                                                                onChange={(e) => this.handleChangeValue('alternativePickupPersonIdentificationNumber', e)} />
                                                            <span className="input-error">
                                                                {
                                                                    (errors.alternativePickupPersonIdentificationNumber && this.state.alternativePickupPersonIdentificationNumber.length > 0 && this.state.alternativePickupPersonIdentificationNumber.length !== documentNumberLength) &&
                                                                    <React.Fragment>Ingresa un documento v&aacute;lido.</React.Fragment>
                                                                }
                                                                {
                                                                    (errors.alternativePickupPersonIdentificationNumber && (!this.state.alternativePickupPersonIdentificationNumber || this.state.alternativePickupPersonIdentificationNumber.length === 0)) &&
                                                                    <React.Fragment>Campo obligatorio.</React.Fragment>
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="alert">
                                            <div className="alert-icon">
                                                <IconInfo></IconInfo>
                                            </div>
                                            <div className="alert-content">
                                                Esta persona será la única que podrá retirar tu compra.
                                            </div>
                                        </div>
                                        <div className="mt-4 visible-desktop">
                                            <button className="btn btn-main" type="button" onClick={(e) => { this.handleSaveAlternativePickupPerson(); }}>Guardar</button>
                                        </div>
                                    </div>
                                    {
                                        (selectedStore !== null) &&
                                        <React.Fragment>
                                            <div className="col-md-6 col-12">
                                                <h4>Productos</h4>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="item">
                                                            <div className="icon">
                                                                <img src="/product.png" alt="" />
                                                            </div>
                                                            <div className="item__detail">
                                                                {
                                                                    selectedStore.products.map((product, i) => {
                                                                        return (
                                                                            <React.Fragment key={'alternative-person-' + product.productId}>
                                                                                <div className="product-info">
                                                                                    <p>{product.name}</p>
                                                                                    <span>Cantidad: {product.quantity}</span>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (selectedStore.selectedSla) &&
                                                    <React.Fragment>
                                                        <h4>Punto de recojo</h4>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="item">
                                                                    <div className="icon">
                                                                        <img src="/map-marker.png" alt="" />
                                                                    </div>
                                                                    <div className="item__detail">
                                                                        <p>{selectedStore.selectedSla.pickupStoreInfo.friendlyName}</p>
                                                                        <span>{selectedStore.selectedSla.pickupStoreInfo.address.street} {selectedStore.selectedSla.pickupStoreInfo.address.number} {selectedStore.selectedSla.pickupStoreInfo.address.complement}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h4>Reserva de producto</h4>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="item">
                                                                    <div className="icon">
                                                                        <img src="/flag.png" alt="" />
                                                                    </div>
                                                                    <div className="item__detail">
                                                                        <p>Retirar el {this.formatShippingDate(selectedStore)}</p>
                                                                        <p className="scheadule">{this.formatShippingTime(selectedStore)}</p>
                                                                        <span>Recuerda que tendrás 72 horas a partir de la fecha seleccionada para recoger el pedido; luego de este plazo, el pedido se anulará.</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                            <div className="modal-footer visible-mobile">
                                <button className="btn btn-main" type="button" onClick={(e) => { this.handleSaveAlternativePickupPerson(); }}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}

export default Navigation;