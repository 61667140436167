import React from "react";
import * as $ from 'jquery';

class EmailForm extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        email: '',
        identificationNumber: '',
        promotionCheckout: false,
        promoBackground: null,
        promoText: {
            main: 'Ingresa tu correo',
            description: 'Solo usaremos tu correo para identificarte.'
        },
        errors: {},
        isPriorityPassEnabled: false
    }

    componentDidMount = () => {
        this.setScroll();
        this.validatePromotion();
        this.getEmail();
    }

    validatePromotion = () => {
        const { match, promo } = this.props;

        let promoId = match.params.promotionId;

        if (promo) {
            let promoParams = JSON.parse(promo.jsonBanner);
            let imageSize = 'desktop';
            let width = window.innerWidth;
            if (width < 480) {
                imageSize = 'mobile'
            }

            this.setState({
                promotionCheckout: true,
                promoBackground: {
                    backgroundImage: `url(https://ibkshopstarsasprod.blob.core.windows.net/images/promos/${promoParams.backgroundImage.image}-${imageSize}.JPG)`
                },
                promoText: {
                    main: promoParams.title,
                    description: promoParams.description
                }
            })
        }
    }

    setScroll = () => {
        $(window).scroll(function () {
            if ($(window).scrollTop() > 0) {
                $(".promo-background").css("top", "-10px");
            } else {
                $(".promo-background").css("top", "54px");
            }
        })
    }

    getEmail = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        if (email && email != 'null')
            this.setState({ email });
    }

    toggleFieldError = (name, value) => {
        const { errors } = this.state;
        let _errors = errors;

        _errors[name] = value;
        this.setState({ errors: _errors });
    }

    handleChangeEmail = (event) => {
        this.toggleFieldError('email', false);

        let email = event.target.value;

        email = email.toLowerCase();
        email = email.replace(/\s/g, '');

        this.setState({
            email: email
        });
    }

    handleChangeIdentificationNumber = (event) => {
        let value = event.target.value;

        var re = /^[0-9]*$/;
        if (!re.test(value))
            return;

        if (value.length > 9)
            return;

        if (!value || value === "") {
            this.toggleFieldError('identificationNumber', true)
        } else {
            this.toggleFieldError('identificationNumber', false)
        }

        this.setState({
            identificationNumber: value
        });
    }

    handleSubmitEmail = (event) => {
        event.preventDefault();
        const { email, identificationNumber, promotionCheckout } = this.state;

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email === '' || !re.test(email)) {
            this.toggleFieldError('email', true);
            return;
        }

        if (promotionCheckout && (identificationNumber === '' || identificationNumber.length < 8)) {
            this.toggleFieldError('identificationNumber', true);
            return;
        }

        (window["rrApiOnReady"] = window["rrApiOnReady"] || []).push(function () { window["rrApi"].setEmail(email); });

        this.props.handleSubmitEmail(email, identificationNumber);
    }

    render() {
        const { loaders, disabledPromo, isPriorityPassEnabled } = this.props;
        const { errors, promoBackground, promotionCheckout, promoText } = this.state;
        return (
            <div className={'checkout__body__email-form ' + (promotionCheckout ? 'text-white' : '')}>
                {
                    (promotionCheckout) &&
                    <div className="promo-background" style={promoBackground}></div>
                }
                {
                    (!disabledPromo) &&
                    <form className="checkout__body__form border-bottom-0" onSubmit={(e) => { this.handleSubmitEmail(e) }}>
                        <h2 className={promotionCheckout ? 'text-center' : ''} dangerouslySetInnerHTML={{ __html: promoText.main }}></h2>
                        <h5 className={promotionCheckout ? 'text-center' : ''} dangerouslySetInnerHTML={{ __html: promoText.description }}></h5>
                        {
                            (promotionCheckout) &&
                            <div className={'form-group ' + (errors.identificationNumber ? 'invalid' : '')}>
                                <label htmlFor="identificationType">N&uacute;mero de documento</label>
                                <input id="identificationType" value={this.state.identificationNumber} onChange={this.handleChangeIdentificationNumber} type="text" className="form-control" placeholder="Ingresa tu n&uacute;mero de documento" />
                            </div>
                        }
                        <div className={'form-group ' + (errors.email ? 'invalid' : '')}>
                            <label htmlFor="userEmail">Correo electrónico</label>
                            <input id="userEmail" value={this.state.email} onChange={this.handleChangeEmail} type="text" className="form-control" placeholder="correo@shopstar.pe" />
                        </div>
                        <div className="form-group">
                            <button disabled={loaders.start || loaders.email} type="submit" className="btn btn-main w-100">
                                Continuar
                            </button>
                        </div>
                    </form>
                }

                {
                    (disabledPromo) &&
                    <form className="checkout__body__form border-bottom-0">
                        <h2 className={'text-center'}>¡Hola!</h2>
                        <h5 className={'text-center'}>Lamentablemente se agotó el stock que teníamos completado para esta campaña, pero pronto tendremos más novedades y sorpresas para ti.</h5>
                        <h5 className="text-center">Gracias por ser cliente Interbank</h5>
                    </form>
                }

                {/* {
                    (!isPriorityPassEnabled) &&
                    <form className="checkout__body__form border-bottom-0">
                        <h2 className={'text-center'}>¡Hola!</h2>
                        <h5 className={'text-center'}>
                            Por el momento, no tienes habilitada la opción para adquirir Priority Pass.
                        </h5>
                        <h5 className="text-center">
                            Agradecemos tu preferencia y por ser parte de Interbank. Puedes explorar otras opciones en nuestra tienda.
                        </h5>
                        <button
                            type="button"
                            onClick={() => window.location.href = "https://shopstar.pe"}
                            className="btn btn-main w-100"
                            disabled={loaders.start || loaders.email}
                        >
                            Ir a la tienda
                        </button>
                    </form>
                } */}

            </div>
        )
    }
}

export default EmailForm;